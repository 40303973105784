import React from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { CimSicraContenitori } from '../sicrawebData/CimSicraContenitori';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

interface DefuntiTabProps {
  isOpen: boolean;
  onClose: () => void;
  defuntiArray: CimSicraContenitori[];
  onDefuntoClick: (defunto: CimSicraContenitori) => void;
}

const ClickableTableRow = styled(TableRow)`
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #f5f5f5;
  }
  &:active {
    background-color: #e0e0e0;
    transition: background-color 0.1s;
  }
`;

const DefuntiTab: React.FC<DefuntiTabProps> = ({ isOpen, onClose, defuntiArray, onDefuntoClick }) => {

  const { t, i18n } = useTranslation();
  
  return (
    <div style={{ width: '1400px', height: '800px', border: 'none', maxHeight: '800px', overflowY: 'auto' }}>
      {isOpen && (
        <div>
          {/* Titolo della tabella */}
          <h2 style={{ textAlign: 'center', marginBottom: '20px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>{t('defuntoTabTitle')}</h2>       
          <h5 style={{ textAlign: 'center', marginBottom: '20px', top: 0, backgroundColor: 'white', zIndex: 2 }}>{t('defuntoTabHeader')}</h5>       
          {/* Tabella con intestazione fissa */}
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                {/* Intestazione sopra i nomi dei campi */}
                <TableRow>
                  <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>{t('defuntoCell')}</TableCell>
                  <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>{t('dateCell')}</TableCell>
                  <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>{t('cimiteroCell')}</TableCell>
                  <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>{t('elementoCell')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {defuntiArray.map((defunto) => (
                  <ClickableTableRow key={defunto.pkid} onClick={() => onDefuntoClick(defunto)} >
                    <TableCell
                      style={{ cursor: 'pointer' }} 
                    >
                      {defunto.asString}
                    </TableCell>
                    <TableCell>{defunto.dataMorte?.toLocaleString()} </TableCell>
                    <TableCell>{defunto.cimiteroString} </TableCell>
                    <TableCell>{defunto.elementoDes} </TableCell>
                  </ClickableTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default DefuntiTab;