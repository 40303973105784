import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

interface AlertDialogProps {
    open: boolean;
    onClose: () => void;
    message: string;
  }
  
  const AlertDialog: React.FC<AlertDialogProps> = ({ open, onClose, message }) => {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Avviso</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
          onClick={onClose} 
          autoFocus
          variant="contained"
            style={{
              backgroundColor: '#b0c4de',
              color: 'black',
              borderRadius: '20px',
              transition: 'background-color 0.3s, color 0.3s',
              cursor: 'pointer',
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = '#4682b4';
              e.currentTarget.style.color = 'white';
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = '#b0c4de';
              e.currentTarget.style.color = 'black';
            }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  
  export default AlertDialog;