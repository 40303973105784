import { CimSicraCimiteri } from "../sicrawebData/CimSicraCimiteri";
import { CimSicraGisIdList } from "../sicrawebData/CimSicraGisIdList";
import { CimSicraGisInterface } from "../sicrawebData/CimSicraGisInterface";
import { CimSicraSearchParams } from "../sicrawebData/CimSicraSearchParams";

export class CimService {
    private baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = "api/cimService";
    }

    async fetchCimiteri(enteWsLocation: string): Promise<CimSicraCimiteri[]> {
        
        const response = await fetch(`${this.baseUrl}/fetchCimiteri`, {
            headers: {
                'enteWsLocation': enteWsLocation
            },
        });

        if (!response.ok) {
            const message = await response.json();
            throw message.message;
        }
        const data: CimSicraCimiteri[] = await response.json();      
        return data;  
    }

    async estrazioneJSONGrafica(searchParams: CimSicraSearchParams, enteWsLocation: string): Promise<any> {
        
        const response = await fetch(`${this.baseUrl}/estrazioneJSONGrafica`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'enteWsLocation': enteWsLocation
            },
            body: JSON.stringify(searchParams),
        });
        if (!response.ok) {
            const message = await response.json();
            throw message.message;
        }
        const data = await response.json();      
        return data;  
    }

    async fetchGisData(idCimitero: number, enteWsLocation: string): Promise<CimSicraGisInterface> { 

        const response = await fetch(`${this.baseUrl}/fetchGisData/${idCimitero}`, {
            headers: {
                'enteWsLocation': enteWsLocation
            },
        });

        if (!response.ok) {
            const message = await response.json();
            throw message.message;
        }
        const data: JSON = await response.json();   
        let result: CimSicraGisInterface = new CimSicraGisInterface(); 
        result.listaFiltri = data;
        result.idChiamato = idCimitero;

        return result;  
    }

    async getGisFiltersResults(idList: any, enteWsLocation: string): Promise<any> { 

        const response = await fetch(`${this.baseUrl}/getGisFiltersResults`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'enteWsLocation': enteWsLocation
            },
            body: JSON.stringify(idList),
        });
        if (!response.ok) {
            const message = await response.json();
            throw message.message;
        }
        const data = await response.json();      
        return data;  
    }

    async extractChangeView(idList: CimSicraGisIdList, enteWsLocation: string): Promise<any> {

        const response = await fetch(`${this.baseUrl}/extractChangeView`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'enteWsLocation': enteWsLocation
            },
            body: JSON.stringify(idList),
        });

        if (!response.ok) {
            const message = await response.json();
            throw message.message;
        }

        const data = await response.json();      
        return data;  
    }

    async getPathFinder(jsonGrafica: JSON, enteWsLocation: string): Promise<any> {

        const response = await fetch(`${this.baseUrl}/getPathFinder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'enteWsLocation': enteWsLocation
            },
            body: JSON.stringify(jsonGrafica),
        });

        if (!response.ok) {
            const message = await response.json();
            throw message.message;
        }

        const data = await response.json();      
        return data;  
    }

    async savePathFinderRoutes(jsonGrafica: JSON, enteWsLocation: string): Promise<any> {

        const response = await fetch(`${this.baseUrl}/savePathFinderRoutes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'enteWsLocation': enteWsLocation    
            },
            body: JSON.stringify(jsonGrafica),
        });

        if (!response.ok) {
            const message = await response.json();
            throw message.message;
        }

        const data = await response.json();      
        return data;  
    }

    async savePathFinderPoints(jsonGrafica: JSON, enteWsLocation: string): Promise<any> {

        const response = await fetch(`${this.baseUrl}/savePathFinderPoints`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'enteWsLocation': enteWsLocation
            },
            body: JSON.stringify(jsonGrafica),
        });

        if (!response.ok) {
            const message = await response.json();
            throw message.message;
        }

        const data = await response.json();      
        return data;  
    }
}