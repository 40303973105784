import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface LoginFormProps {
    onLogin: (username: string, password: string) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLogin }) => {

    const { t, i18n } = useTranslation();

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState(false);

    const isLoginDisabled = username.trim() === '' || password.trim() === '' || username === undefined || password === undefined;

    const handleLogin = () => {
        if (username && password) {
            onLogin(username, password);
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2,
                backgroundColor: '#2c3e50',
                color: 'white',
                borderRadius: 2,
                width: '300px',
                margin: 'auto',
            }}
        >
            <Typography variant="h5" sx={{ marginBottom: 2 }}>
                {t("login")}
            </Typography>
            <TextField
                label={t("username")}
                variant="outlined"
                fullWidth
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Username"
                sx={{ marginBottom: 2, backgroundColor: 'white', borderRadius: 2 }}
            />
            <TextField
                label={t("password")}
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                sx={{ marginBottom: 2, backgroundColor: 'white', borderRadius: 2 }}
                InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                }}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleLogin}
                disabled={isLoginDisabled}
                onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = '#4682b4'; // Blu meno acceso
                    e.currentTarget.style.color = 'white';
                }}
                onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = '#b0c4de'; // Colore bianco/grigio chiaro
                    e.currentTarget.style.color = 'black';
                }}
                style={{ 
                    marginTop: 2,
                    transition: 'background-color 0.3s, color 0.3s',
                    borderRadius: '20px',
                    color: isLoginDisabled ? 'darkgrey' : 'black',
                    backgroundColor: isLoginDisabled ? 'lightgrey' : '#b0c4de',}}
            >
                {t("login")}
            </Button>
        </Box>
    );
};

export default LoginForm;