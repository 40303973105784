/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DirectionsRenderer, GoogleMap, LoadScriptNext, Polyline } from '@react-google-maps/api';
import { GisConstants } from '../utils/gisConstants';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import translate from '../utils/translate';

interface MapComponentProps {
  coordinates: { lat: number; lng: number }[];
  visible: boolean;
  onCloseButton: () => void;
}

const MapComponent: React.FC<MapComponentProps> = ({ coordinates: initialCoordinates, visible, onCloseButton }) => {

  const APIKEY = GisConstants.MAPS_APIKEY;

  const { t, i18n } = useTranslation();
  
  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  const [userPosition, setUserPosition] = useState<{ lat: number; lng: number } | null>(null);
  const [directionsResponse, setDirectionsResponse] = useState<any>(null);
  const [showDirections, setShowDirections] = useState<boolean>(false);
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>(initialCoordinates[0] || { lat: 0, lng: 0 });
  const [mapZoom, setMapZoom] = useState<number>(20);
  const [isApiLoaded, setIsApiLoaded] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState(initialCoordinates);
  const [advancedMarker, setAdvancedMarker] = useState<google.maps.marker.AdvancedMarkerElement | null>(null);

  const mapRef = useRef<google.maps.Map | null>(null);
  const mapContainerId = 'map-container';

  useEffect(() => {
    if (navigator.geolocation) {
      const watchId = navigator.geolocation.watchPosition((position) => {
        const newUserPosition = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setUserPosition(newUserPosition);
        updatePolyline(newUserPosition);
      });

      return () => {
        navigator.geolocation.clearWatch(watchId);
      };
    }
  }, []);

  const updatePolyline = useCallback((newUserPosition: { lat: number | google.maps.LatLng | google.maps.LatLngLiteral; lng: number | boolean | null | undefined; }) => {
    if (window.google && window.google.maps) {
      const newCoordinates = initialCoordinates.filter((coord) => {
        const distance = google.maps.geometry.spherical.computeDistanceBetween(
          new google.maps.LatLng(newUserPosition.lat, newUserPosition.lng),
          new google.maps.LatLng(coord.lat, coord.lng)
        );
        return distance > 5; // Rimuovi i punti entro 5 metri dall'utente
      });
      setCoordinates(newCoordinates);
    }
  }, [initialCoordinates]);

  useEffect(() => {
    if (userPosition && coordinates.length > 0 && showDirections) {
      const directionsService = new google.maps.DirectionsService();
      directionsService.route(
        {
          origin: userPosition,
          destination: coordinates[0],
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirectionsResponse(result);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  }, [userPosition, coordinates, showDirections]);

  useEffect(() => {
    const loadAdvancedMarker = async () => {
      if (window.google && mapRef.current && userPosition) {
        try {
          const { AdvancedMarkerElement } = await google.maps.importLibrary('marker') as { AdvancedMarkerElement: typeof google.maps.marker.AdvancedMarkerElement };

          const mapContainer = document.getElementById(mapContainerId);
          if (mapContainer) {
            // Remove the old marker by removing the associated HTML element
            const markerElement = document.querySelector('.advanced-marker');
            if (markerElement) {
              markerElement.remove();
            }
          }

          // Create a new AdvancedMarkerElement
          const markerDiv = document.createElement('div');
          markerDiv.className = 'advanced-marker';
          markerDiv.style.backgroundImage = 'url(https://maps.google.com/mapfiles/kml/paddle/blu-circle.png)';
          markerDiv.style.backgroundSize = 'cover';
          markerDiv.style.width = '50px';
          markerDiv.style.height = '50px';

          const newMarker = new AdvancedMarkerElement({
            position: userPosition,
            map: mapRef.current,
            content: markerDiv,
          });

          setAdvancedMarker(newMarker);
        } catch (error) {
          console.error('Error loading AdvancedMarkerElement:', error);
        }
      }
    };

    loadAdvancedMarker();
  }, [ userPosition]);

  const handleToggleDirections = useCallback(() => {
    setShowDirections((prevShowDirections) => {
      if (prevShowDirections) {
        setMapCenter(coordinates[0]); // Reset map center when hiding directions
        setMapZoom(20);
        if (mapRef.current) {
          mapRef.current.setCenter(coordinates[0]);
          mapRef.current.setZoom(20);
        }
      }
      return !prevShowDirections;
    });
  }, [coordinates]);

  const mapOptions = {
    // mapTypeId: 'satellite', // #6 cambio visualizzazione predefinita
    mapId: mapContainerId,
    zoomControl: true,
    streetViewControl: false, 
    fullscreenControl: true, 
  };

  return (
    <div className={`map-overlay ${visible ? 'visible' : ''}`}>
      <LoadScriptNext googleMapsApiKey={APIKEY} onLoad={() => setIsApiLoaded(true)}>
        <>
          {isApiLoaded && (
            <GoogleMap
              id={mapContainerId}
              mapContainerStyle={containerStyle}
              center={mapCenter}
              zoom={mapZoom}
              options={mapOptions}
              onLoad={(map) => {
                mapRef.current = map;
              }}
            >
              {/* {userPosition && (
                <Marker
                  position={userPosition}
                  icon={{
                    url: 'https://maps.google.com/mapfiles/kml/paddle/blu-circle.png',
                    scaledSize: new window.google.maps.Size(50, 50),
                  }}
                />
              )} */}
              {showDirections && directionsResponse && (
                <DirectionsRenderer directions={directionsResponse} />
              )}
              <Polyline
                path={coordinates}
                options={{
                  strokeColor: '#FF0000',
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                  icons: [{
                    icon: {
                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                      strokeColor: '#FF0000',
                    },
                    offset: '100%',
                    repeat: '100px',
                  }]
                }}
              />
            </GoogleMap>
          )}
        </>
      </LoadScriptNext>
      <div className="button-container">
        <Button
          variant="contained"
          style={{
            backgroundColor: '#b0c4de',
            color: 'black',
            borderRadius: '20px',
            transition: 'background-color 0.3s, color 0.3s',
            cursor: 'pointer',
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor = '#4682b4';
            e.currentTarget.style.color = 'white';
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = '#b0c4de';
            e.currentTarget.style.color = 'black';
          }}
          onClick={handleToggleDirections}
        >
          {showDirections ? translate('hideRoute') : translate('showRoute')}
        </Button>
        <Button
          variant="contained"
          style={{
            backgroundColor: '#b0c4de',
            color: 'black',
            borderRadius: '20px',
            transition: 'background-color 0.3s, color 0.3s',
            cursor: 'pointer',
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor = '#4682b4';
            e.currentTarget.style.color = 'white';
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = '#b0c4de';
            e.currentTarget.style.color = 'black';
          }}
          onClick={onCloseButton}
        >
          {t('mapsExit')}
        </Button>
      </div>
    </div>
  );
};

export default MapComponent;