import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Footer: React.FC = () => {
  return (
    <Box component="footer">
      <Typography variant="body2" color="textSecondary">
        &copy; {new Date().getFullYear()} C&C Sistemi S.R.L. All rights reserved.
      </Typography>
      <Box>
        <Link href="#" color="inherit" sx={{ mx: 1 }}>
          Privacy
        </Link>
        <Link href="#" color="inherit" sx={{ mx: 1 }}>
          Terms
        </Link>
        <Link href="#" color="inherit" sx={{ mx: 1 }}>
          Contact
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
