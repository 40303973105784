import proj4 from 'proj4';

const gaussBoaga = '+proj=tmerc +lat_0=0 +lon_0=9 +k=0.9996 +x_0=1500000 +y_0=0 +ellps=intl +units=m +no_defs';
// const gaussBoaga = '+proj=tmerc +lat_0=0 +lon_0=9 +k=0.9996 +x_0=1500000 +y_0=0 +datum=ROME +units=m +no_defs';
// Definizione del sistema di riferimento WGS84
const wgs84 = '+proj=longlat +datum=WGS84 +no_defs';

export const convertGaussBoagaToLatLng = (x: any, y: any) => {
  const [lon, lat] = proj4(gaussBoaga, wgs84, [x, y]);
  return { lat, lng: lon };
};

export function calculateDistance(lat1: number, lng1: number, lat2: number, lng2: number) {
  const R = 6371; // Raggio della Terra in km
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLng = (lng2 - lng1) * Math.PI / 180;
  const a = 
    0.5 - Math.cos(dLat) / 2 + 
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
    (1 - Math.cos(dLng)) / 2;

  return R * 2 * Math.asin(Math.sqrt(a));
}