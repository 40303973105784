export class UtilsService {
    private baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = "api/utilities";
    }

    async getImage(filename: string): Promise<string> {
        
        const response = await fetch(`${this.baseUrl}/image?filename=${filename}`);

        if (!response.ok) {
            return "";
        }
        const data = await response;
        const blob = await data.blob();
        return URL.createObjectURL(blob);
    }

    async getConfigFile(): Promise<any> {
        
        const response = await fetch(`${this.baseUrl}/getConfigFile`);

        if (!response.ok) {
            const message = await response.json();
            throw message.message;
        }
        const data = await response.json();
        return data;
    }

    async checkCredentials(username: string, password: string): Promise<boolean> {
        
        const response = await fetch(`${this.baseUrl}/checkCredentials?username=${username}&password=${password}`);

        if (!response.ok) {
            const message = await response.json();
            throw message.message;
        }
        const data = await response.json();
        return data;
    }

}