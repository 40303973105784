/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import background from './cim_background.jpg';
import postRobot from 'post-robot';
import './App.css';
import MenuIcon from '@mui/icons-material/Menu';
import { Autocomplete, Avatar, Backdrop, Box, Button, CircularProgress, Container, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Drawer, Fade, FormControlLabel, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { CimService } from './services/CimService.service';
import { CimSicraCimiteri } from './sicrawebData/CimSicraCimiteri';
import { CimSicraSearchParams } from './sicrawebData/CimSicraSearchParams';
import { CimSicraGisIdList } from './sicrawebData/CimSicraGisIdList';
import { CimSicraContenitori } from './sicrawebData/CimSicraContenitori';
import DefuntiTab from './props/DefuntiTabProps';
import AlertDialog from './props/AlertDialog';
import { useTranslation } from 'react-i18next';
import translate from './utils/translate';
import { calculateDistance, convertGaussBoagaToLatLng } from './utils/coordinateConversion';
import MapComponent from './props/MapComponent';
import { GisConstants } from './utils/gisConstants';
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Footer from './props/Footer';
import { UtilsService } from './services/UtilsService.service';
import { AccountCircle, ExitToApp, Person, Settings } from '@mui/icons-material';
import LoginForm from './props/LoginForm';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Popup from './props/Popup';

function App() {

  const [loading, setLoading] = useState(true);
  const [selectedValue, setSelectedValue] = useState<CimSicraCimiteri | null>(null);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly CimSicraCimiteri[]>([]);
  const [deceasedName, setDeceasedName] = useState('');
  const [deceasedSurname, setDeceasedSurname] = useState('');
  const [loadingPopup, setLoadingPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false); 
  const [showDefuntiTable, setShowDefuntiTable] = useState(false); 
  const [searchParams, setSearchParams] = useState<CimSicraSearchParams | null>(null);
  const [jsonGrafica, setJSONGrafica] = useState<any | null>(null);
  const [isComboDisabled, setIsComboDisabled] = useState(false);
  const [isNomeDisabled, setIsNomeDisabled] = useState(false);
  const [isCognomeDisabled, setIsCognomeDisabled] = useState(false);
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);
  const [firstTime, setFirstTime] = useState(true);
  const [defuntiArray, setDefuntiArray] = useState<CimSicraContenitori[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isForwardDisabled, setForwardDisabled] = useState(false);
  const [isBackwardDisabled, setBackwardDisabled] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isPathFinderMode, setPathFinderMode] = useState(false);
  const [isPointMode, setPointMode] = useState(false);
  const [viewPathButton, setViewPathText] = useState<string>(translate("fastestRoute"));
  const [viewPathDisabled, setViewPathDisabled] = useState(false);
  const [description, setDescription] = useState<string | null>(null);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [resolveDescriptionPromise, setResolveDescriptionPromise] = useState<((value: string) => void) | "">("");
  const [isPointTableVisible, setPointTableVisible] = useState(false);
  const [tableData, setTableData] = useState<{ identity: string; nearest?: boolean }[]>([]);
  const [resolvePointPromise, setResolvePointPromise] = useState<((value: string) => void) | null>(null);
  const [coordinates, setCoordinates] = useState<{ lat: number; lng: number }[]>([]);
  const [showMap, setShowMap] = useState(false);
  const [isGeolocationDialogOpen, setGeolocationDialogOpen] = useState<boolean>(false);
  const [enteLogo, setEnteLogo] = useState<string | null>('');
  const [backgroundImage, setBackgroundImage] = useState<string>(background);
  const [title, setTitle] = useState<string>('');
  const [subtitle, setSubtitle] = useState<string>('');
  const [config, setConfig] = useState(null);
  const [enteConfig, setEnteConfig] = useState(null);
  const [userAnchorEl, setUserAnchorEl] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState<string>('');
  const [openEntrance, setOpenEntrance] = useState(false);
  const [messageEntrance, setMessageEntrance] = useState('');
  const [switchChecked, setSwitchChecked] = useState(false);
  const [switchDisabled, setSwitchDisabled] = useState(false);
  
  const selectedValueRef = useRef<CimSicraCimiteri | null>(null); 
  const searchParamsRef = useRef<CimSicraSearchParams | null>(null); 
  const jsonGraficaParamsRef = useRef<any | null>(null); 
  const firstTimeParamsRef = useRef<boolean>(true); 
  const elementoDefuntoRef = useRef<number | null>(null);
  const forwardParamsRef = useRef<boolean>(true); 
  const backwardParamsRef = useRef<boolean>(true); 
  const enteConfigReference = useRef<any | null>(null); 
  const STARTING_POINT = GisConstants.STARTING_POINT;

  let attivazioneListener: any;
  let changeViewListener: any;
  let filterListener: any;
  let backwardListener: any;
  let jsonListener: any;
  let desListener: any;
  let showEntranceListener: any

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const cimService = new CimService(GisConstants.SERVICE_LOCATION)
  const utilsService = new UtilsService(GisConstants.SERVICE_LOCATION)

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const updateSubtitle = (newCim: string) => {
    setSubtitle(newCim);
  };

  const checkGeolocationPermission = async () => {
    try {
      const result = await navigator.permissions.query({ name: 'geolocation' });
      if (result.state === 'prompt') {
        setGeolocationDialogOpen(true);
      }
    } catch (error) {
      console.error('Error checking geolocation permissions:', error);
    }
  };

  const disableComponents = () => {
    setIsNomeDisabled(true);
    setIsCognomeDisabled(true);
    setIsComboDisabled(true);
    setIsSearchDisabled(true);
  }

  const requestGeolocationPermission = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        () => {
          // Permesso concesso, non fare nulla
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            console.error('Geolocation permission denied');
          }
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const handleGeolocationAgree = () => {
    setGeolocationDialogOpen(false);
    requestGeolocationPermission();
  };

  const handleGeolocationDisagree = () => {
    setGeolocationDialogOpen(false);
  };

  useEffect(() => {
    checkGeolocationPermission();
  }, []);

  const handleUserIconClick = (event: { currentTarget: React.SetStateAction<HTMLElement | null>; }) => {
    setUserAnchorEl(event.currentTarget);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setUserAnchorEl(null);
      setDrawerOpen(false);
  };

  const handleOpenEntrance = () => {
    setOpenEntrance(true);
  };

  const handleCloseEntrance = () => {
    setOpenEntrance(false);
  };

  const handleSwitchChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {

    const iframeElement = document.getElementById('graficaIFrame') as HTMLIFrameElement | null;

    if (iframeElement) {

      const state = event.target.checked;
      setSwitchChecked(state);
      
      const childWindow = iframeElement.contentWindow;

      const jsonGrafica = { ...jsonGraficaParamsRef.current } as any
      const pkidCim = jsonGrafica['pkid_cim']
  
      postRobot.send(childWindow, "highlightPaths", {enabled: state, pkid: pkidCim});
    }
  }

  const handleLogin = (username: string, password: string) => {
    const fetchData = async () => {
      try {
        const successLogin = await utilsService.checkCredentials(username, password);
        if(successLogin) {
          toast.success(`Benvenuto ${username}`, { autoClose: 2000 })
          setIsLoggedIn(true);
          setUserName(username);
          setDrawerOpen(false);
        } else 
            showError(translate('wrongCredentials'))
      } catch (error: any) {
        showError(error);
      }
    }

    fetchData();
  };

  const handleLogout = () => {
    toast.success(translate("logout"), { autoClose: 2000 })
    setIsLoggedIn(false);
    setUserName('');
    setDrawerOpen(false);
  };

  const handlePathfinderClick = () => {
    setAnchorEl(null);
    setSwitchDisabled(true);

    const iframeElement = document.getElementById('graficaIFrame') as HTMLIFrameElement | null;

    if (iframeElement) {

      const childWindow = iframeElement.contentWindow;

      const jsonGrafica = { ...jsonGraficaParamsRef.current } as any
      const pkidCim = jsonGrafica['pkid_cim']
  
      postRobot.send(childWindow, "pathFinderMode", {enabled: true, pkid : pkidCim});
      setPathFinderMode(true);
    }
  }

  const handleNavMode = () => {
    setSwitchChecked(false);
    if (!isPathFinderMode)
      setSwitchDisabled(false);
    setAnchorEl(null);

    const iframeElement = document.getElementById('graficaIFrame') as HTMLIFrameElement | null;

    if (iframeElement) {

      const childWindow = iframeElement.contentWindow;

      const jsonGrafica = { ...jsonGraficaParamsRef.current } as any
      const pkidCim = jsonGrafica['pkid_cim']

      postRobot.send(childWindow, "pathFinderMode", {enabled: false, pkid: pkidCim});
      setPathFinderMode(false);
    }
  }

  const handleOpenDes = (): Promise<string> => {
    setPopupVisible(true);

    return new Promise<string>((resolve) => {
      setResolveDescriptionPromise(() => resolve);
  });
  };

  const handleCloseDes = () => {
    setPopupVisible(false);
    setDescription('');
  };

  const handleSubmit = () => {
    if (resolveDescriptionPromise) {
        const desc = description || ''
        resolveDescriptionPromise(desc);
        setResolveDescriptionPromise("");
    }
    handleCloseDes();
};

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 20) {
        setDescription(event.target.value);
    }
  };


  const handleOpenPointsTab = (data: { identity: string, nearest: boolean }[]): Promise<string> => {
    setTableData(data.map(point => ({ identity: point.identity, nearest: point.nearest })));
    setPointTableVisible(true);
    return new Promise<string>((resolve) => {
        setResolvePointPromise(() => resolve);
    });
  };

  const handleClosePointsTab = () => {
    setPointTableVisible(false);
    setTableData([]);
    if (resolvePointPromise) {
        resolvePointPromise("");
        setResolvePointPromise(null);
    }
  };

  const handlePointClick = (identity: string) => {
    if (resolvePointPromise) {
        resolvePointPromise(identity);
        setResolvePointPromise(null);
    }
    handleClosePointsTab();
  };

  const handleCloseGMaps = () => {
    setShowMap(false);
  }

  const savePathFinderRoutes = () => {

    setAnchorEl(null);

    const iframeElement = document.getElementById('graficaIFrame') as HTMLIFrameElement | null;

    if (iframeElement) {
      const childWindow = iframeElement.contentWindow;

      postRobot.send(childWindow, "pathFinderMode", {enabled: false}).then(response => {
        setPathFinderMode(false);

        const jsonGrafica = { ...jsonGraficaParamsRef.current } as JSON

        const fetchData = async () => {

          const enteConfigRef = {...enteConfigReference.current} as any
          if(enteConfigRef != null) {
            const jsonPathfinder = await cimService.savePathFinderRoutes(jsonGrafica, enteConfigRef['enteWSLocation']);
      
            setJSONGrafica(jsonPathfinder);
            jsonGraficaParamsRef.current = jsonPathfinder;
          }
          
        }

        fetchData();
      }
        
      )
      
    } 
  }

  const insertEntries = () => {

    setAnchorEl(null);

    const iframeElement = document.getElementById('graficaIFrame') as HTMLIFrameElement | null;

    if (iframeElement) {

      const childWindow = iframeElement.contentWindow;
  
      postRobot.send(childWindow, "pathFinderMode", {enabled: true, onlyEntries: true});
      setPathFinderMode(true);
      setPointMode(true);
    }
  }

  const saveEntries = () => {

    setAnchorEl(null);

    const iframeElement = document.getElementById('graficaIFrame') as HTMLIFrameElement | null;

    if (iframeElement) {

      const childWindow = iframeElement.contentWindow;
  
      postRobot.send(childWindow, "pathFinderMode", {enabled: false, onlyEntries: true}).then(response => {

        setPathFinderMode(false);
        setPointMode(false);
  
        const jsonGrafica = { ...jsonGraficaParamsRef.current } as JSON
  
        const fetchData = async () => {
  
          const enteConfigRef = {...enteConfigReference.current} as any
          if(enteConfigRef != null) {
            const jsonPathfinder = await cimService.savePathFinderPoints(jsonGrafica, enteConfigRef['enteWSLocation']);
        
            setJSONGrafica(jsonPathfinder);
            jsonGraficaParamsRef.current = jsonPathfinder;
          }
        }
  
        fetchData();
      })
    }
  }

  const handleClosePopup = () => {
    setSwitchChecked(false);
    setShowPopup(false);
    setPathFinderMode(false);
    setViewPathDisabled(false);
    setBackwardDisabled(true);
    setViewPathText(translate('fastestRoute'));
    setViewPathAction(() => handleRouteClick)
  };

  const handleCloseTab = () => {
    setShowDefuntiTable(false);
  };

  const showError = (error: string) => {
    setErrorMessage("Errore: " + error);
  };

  const closeError = () => {
    setErrorMessage(null);
  };

  const handleBackClick = () => {

    setForwardDisabled(false)

    const iframeElement = document.getElementById('graficaIFrame') as HTMLIFrameElement | null;

    if (iframeElement) {

      const childWindow = iframeElement.contentWindow;
  
      postRobot.send(childWindow, "backFunction");
    }
  }

  const handleForwardClick = () => {

    setBackwardDisabled(false);

    const iframeElement = document.getElementById('graficaIFrame') as HTMLIFrameElement | null;

    const jsonGrafica = { ...jsonGraficaParamsRef.current } as JSON
    
    // console.log(jsonGrafica)
    if (iframeElement) {
     
      if('figli' in jsonGrafica) {
        const figli = jsonGrafica.figli as JSON[]

        const pkid_cim = findPkidSelected(figli);

        if (pkid_cim !== null) {
          const childWindow = iframeElement.contentWindow;
          
          postRobot.send(childWindow, "forwardFunction", {pkid_cim});
        } else {
          console.log('Nessun figlio con selected uguale a 1 trovato.');
        }
      }
    }
  }

  const handleRouteClick = async () => {

    const iframeElement = document.getElementById('graficaIFrame') as HTMLIFrameElement | null;

    const jsonGrafica = { ...jsonGraficaParamsRef.current }

    console.log(jsonGrafica)

    if ('grafica' in jsonGrafica) { 
      const polylines = jsonGrafica.grafica.Group.polylines as JSON[]
      let startingPoints = await findStartingPoints(polylines)

      if (startingPoints.length > 1) {
        const selectedIdentity = await handleOpenPointsTab(startingPoints);
        console.log("Selected Identity:", selectedIdentity);
        
        if (selectedIdentity !== "") {
          const filteredPolylines = polylines.filter((polyline) => {
            if('identity' in polyline) {
              const identity = (polyline as any).identity as string;
              if (identity.startsWith(STARTING_POINT)) {
                return identity === selectedIdentity;
              } else {
                return true; // Mantieni tutte le polilinee che non iniziano con 'startingPointPathfinder_'
              }
            }
            return true;
          })

          console.log("POLILINEE FILTRATE:", filteredPolylines)

          jsonGrafica.grafica.Group.polylines = filteredPolylines;

          setJSONGrafica(jsonGrafica)
          jsonGraficaParamsRef.current = jsonGrafica;

          const fetchData = async () => {

            try {
              const enteConfigRef = {...enteConfigReference.current} as any
              if(enteConfigRef != null) {
                const jsonPathfinder = await cimService.getPathFinder(jsonGrafica, enteConfigRef['enteWSLocation']);
        
                setJSONGrafica(jsonPathfinder);
                jsonGraficaParamsRef.current = jsonPathfinder;
        
                if (iframeElement) {
        
                  const childWindow = iframeElement.contentWindow;
                  postRobot.send(childWindow, "showPath", { jsonPathfinder });
        
                  setViewPathText(translate('viewOnGoogleMaps'));
                  setSwitchChecked(false);
                  setSwitchDisabled(true);
                  // setViewPathAction(() => createGMapsPerspective);
                  setViewPathAction(() => createApiPerspective); 
                }
              }
            } catch (error: any) {
              handleClosePointsTab();
              showError(error);
            } 
          }
          fetchData();
        }
      } else if (startingPoints.length === 1) {

        const fetchData = async () => {

          try {
            const enteConfigRef = {...enteConfigReference.current} as any
            if(enteConfigRef != null) {
              const jsonPathfinder = await cimService.getPathFinder(jsonGrafica, enteConfigRef['enteWSLocation']);
      
              setJSONGrafica(jsonPathfinder);
              jsonGraficaParamsRef.current = jsonPathfinder;
      
              if (iframeElement) {
      
                const childWindow = iframeElement.contentWindow;
                postRobot.send(childWindow, "showPath", { jsonPathfinder });
      
                setViewPathText(translate('viewOnGoogleMaps'));
                // setViewPathAction(() => createGMapsPerspective); 
                setViewPathAction(() => createApiPerspective);
              }
            }
          } catch (error: any) {
            showError(error);
          } 
        }
        fetchData();
      } else if (startingPoints.length === 0 || startingPoints === undefined) 
        showError(translate("noStartException"))
    }
    
  }

  const createGMapsPerspective = (): Promise<void> => {
    return new Promise((resolve, reject) => {
        try {
            const jsonGrafica = jsonGraficaParamsRef.current;

            if ('insertMode' in jsonGrafica.grafica.Group) {
                if (jsonGrafica.grafica.Group.insertMode === 2) {
                    if ('grafica' in jsonGrafica) {
                        const polylines = jsonGrafica.grafica.Group.polylines as JSON[];
                        const path = findGeneratedPath(polylines);

                        const gaussBoagaPoints = path.xs.map((x: any, index: number) => ({
                            x,
                            y: path.ys[index]
                        }));

                        const convertedWGS = gaussBoagaPoints.map((point: { x: any; y: any; }) => convertGaussBoagaToLatLng(point.x, point.y));

                        console.log("PUNTI CONVERTITI IN WGS: ", convertedWGS);

                        openGoogleMaps(convertedWGS);
                    }
                } else {
                    showError(translate("wrongLayer"));
                }
            } else {
                showError(translate("isOldGis"));
            }
            resolve();
        } catch (error) {
            reject(error);
        }
    });
  };

  const createApiPerspective = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        const jsonGrafica = jsonGraficaParamsRef.current;

        if ('insertMode' in jsonGrafica.grafica.Group) {
          if (jsonGrafica.grafica.Group.insertMode === 2) {
            if ('grafica' in jsonGrafica) {
              const polylines = jsonGrafica.grafica.Group.polylines as JSON[];
              const path = findGeneratedPath(polylines);

              const gaussBoagaPoints = path.xs.map((x: any, index: number) => ({
                x,
                y: path.ys[index]
              }));

              const convertedWGS = gaussBoagaPoints.map((point: { x: any; y: any; }) => convertGaussBoagaToLatLng(point.x, point.y));

              console.log("PUNTI CONVERTITI IN WGS: ", convertedWGS);

              setCoordinates(convertedWGS);
              setShowMap(true);
            }
          } else {
            showError(translate("wrongLayer"));
          }
        } else {
          showError(translate("isOldGis"));
        }
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const openGoogleMaps = (points: any) => {
    if (points.length > 1) {
      const origin = `${points[0].lat},${points[0].lng}`;
      const destination = `${points[points.length - 1].lat},${points[points.length - 1].lng}`;
      const waypoints = points.slice(1, -1).map((point: { lat: any; lng: any; }) => `${point.lat},${point.lng}`).join('|');
      
      const url = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&waypoints=${waypoints}`;
      window.open(url, '_blank');
    } else {
      showError(translate("wrongPathFinder"));
    }
  };
  
  const [viewPathAction, setViewPathAction] = useState(() => handleRouteClick);

  function findGeneratedPath(polylines: JSON[]): any {

    for (const polyline of polylines) {
      if ("identity" in polyline) {
        if (polyline.identity === "pathFinderResult")
          return polyline;
      }
    }
      
  }

  function findStartingPoints(polylines: JSON[]): Promise<any> {

    return new Promise((resolve, reject) => {
      let result: JSON[] = [];
  
      for (const polyline of polylines) {
        if ("identity" in polyline) {
          let identity = polyline.identity as String;
          if (identity.startsWith(STARTING_POINT))
            result.push(polyline);
        }
      }
  
      let minDistance = Infinity;
      let closestPoint: any = null;
  
      const getPosition = () => {
        return new Promise<GeolocationPosition>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
      };
  
      getPosition()
            .then((position: GeolocationPosition) => {
                if (position != null) {
                    for (const startingPoint of result) {
                        if ("xs" in startingPoint && "ys" in startingPoint) {
                            const xs = startingPoint.xs as number[];
                            const ys = startingPoint.ys as number[];
  
                            if (Array.isArray(xs) && Array.isArray(ys) && xs.length > 0 && ys.length > 0) {
                                const x = xs[0];
                                const y = ys[0];
                                const pointLatLng = convertGaussBoagaToLatLng(x, y);
  
                                const distance = calculateDistance(position.coords.latitude, position.coords.longitude, pointLatLng.lat, pointLatLng.lng);
  
                                if (distance < minDistance) {
                                    minDistance = distance;
                                    closestPoint = startingPoint;
                                }
                            }
                        }
                    }
  
                    for (const startingPoint of result) {
                        (startingPoint as any).nearest = false;
                    }
  
                    if (closestPoint) {
                        (closestPoint as any).nearest = true;
                    }
                }
  
                resolve(result);
            })
        .catch((error) => {
          console.error('Error getting geolocation:', error);
          
          for (const startingPoint of result) 
            (startingPoint as any).nearest = false;
          resolve(result);
        });
    });
      
  }

  function checkForwardAvailability(figli: JSON[]): void {

    const tipoElem: boolean = findTipoFigli(figli);
        
    if (tipoElem)
      setForwardDisabled(true)
    else
      setForwardDisabled(false)
  }

  function findPkidSelected(figli: any[]) {
    for (const figlio of figli) {
      if (figlio.selected === 1) {
        return figlio.pkid_cim;
      }
    }
    return null; // Se nessun figlio ha selected uguale a 1
  }

  function findTipoFigli(figli: any[]): boolean {
    for (const figlio of figli) {
      if (figlio.tipo === 'elemento') {
        return true;
      }
      return false;
    }
    return false;
  }

  const handleDefuntoClick = (defunto: CimSicraContenitori) => {
    
    const clickedDefunto = defunto;

    console.log('Defunto cliccato:', clickedDefunto);

    if (!clickedDefunto != null) {

      handleCloseTab();
      setLoadingPopup(true);

      const fetchData = async () => {

        const enteConfigRef = {...enteConfigReference.current} as any
        if(enteConfigRef != null) {
          let idList = new CimSicraGisIdList();
          const id = defunto.pkidElementoCimitero;
          idList.idToRender = id;
          const secondaryId = defunto.pkid
          elementoDefuntoRef.current = secondaryId;
          let supportRenderableIds: number[] = [];
          idList.renderableIds = supportRenderableIds
          secondaryId != null && idList.renderableIds.push(secondaryId);
      
          let jsonGraficaCur = await cimService.extractChangeView(idList, enteConfigRef['enteWSLocation'])

          try {
            setFirstTime(true);
            firstTimeParamsRef.current = true;
            jsonGraficaCur.defunto = clickedDefunto.asString;
            jsonGraficaCur.elementoFullDes = clickedDefunto.elementoDes;

            setJSONGrafica(jsonGraficaCur);
            jsonGraficaParamsRef.current = jsonGraficaCur
            let updatedSearchParams = { ...searchParams}
            updatedSearchParams.idCimitero = defunto.pkidCimitero
            searchParamsRef.current = updatedSearchParams
            setSearchParams(updatedSearchParams);
            setShowPopup(true);
            
    
          } catch (error: any) {
            showError(error);
          } finally {
            setLoadingPopup(false)
          }
        }
      }

      fetchData();
      
    }
    
  };
  
  const handleResearchClick = () => {
    setLoadingPopup(true);
    
    const fetchData = async () => {

      const enteConfigRef = {...enteConfigReference.current} as any
      if(enteConfigRef != null) {
        const updatedSearchParams = { ...searchParams}
        const updatedSelectedValue = { ...selectedValueRef.current}

        if(updatedSelectedValue !== null && updatedSelectedValue !== undefined)
          updatedSearchParams.idCimitero = updatedSelectedValue.idCimitero;
        updatedSearchParams.nominativo = `${deceasedName}|${deceasedSurname}`

        setSearchParams(updatedSearchParams);
        searchParamsRef.current = updatedSearchParams
        try {
          setFirstTime(true);
          firstTimeParamsRef.current = true;
          const jsonResponse = await cimService.estrazioneJSONGrafica(updatedSearchParams, enteConfigRef['enteWSLocation']);

          if('defunti' in jsonResponse) {

          const defuntiArray: CimSicraContenitori[] = jsonResponse.defunti.map((defunto: any) => ({
            asString: defunto.asString,
            pkid: defunto.elePkid,
            pkidElementoCimitero: defunto.pkidElementoCimitero,
            pkidCimitero: defunto.cimiPkid,
            cimiteroString: defunto.cimiString,
            dataMorte: defunto.dataMorte,
            elementoDes: defunto.elementoDes,
          }))

          setDefuntiArray(defuntiArray);

          setShowDefuntiTable(true);

          } else {
            setJSONGrafica(jsonResponse);
            jsonGraficaParamsRef.current = jsonResponse

            if(updatedSearchParams.idCimitero === undefined) {
              updatedSearchParams.idCimitero = jsonResponse.pkid_cim
              setSearchParams(updatedSearchParams);
              searchParamsRef.current = updatedSearchParams
            }
            if('cim_ele_pkid' in jsonResponse && jsonResponse.cim_ele_pkid !== undefined) {
              elementoDefuntoRef.current = jsonResponse.cim_ele_pkid;
            }
            setShowPopup(true);
          }

        } catch (error: any) {
          showError(error);
        } finally {
          setLoadingPopup(false)
        }
      }
      
    };

    fetchData();
  }

  useEffect(() => {
    setIsSearchDisabled(deceasedName.trim() === '' && deceasedSurname.trim() === '');
  }, [deceasedName, deceasedSurname]);

  useEffect(() => {
    setForwardDisabled(isForwardDisabled);
    forwardParamsRef.current = isForwardDisabled
  }, [isForwardDisabled]);

  useEffect(() => {
    setBackwardDisabled(isBackwardDisabled);
    backwardParamsRef.current = isBackwardDisabled
  }, [isBackwardDisabled]);

  useEffect(() => {
    searchParamsRef.current = searchParams;
  }, [searchParams]);

  useEffect(() => {
    jsonGraficaParamsRef.current = jsonGrafica;
  }, [jsonGrafica]);

  useEffect(() => {
    if (config) {
      const pathName = window.location.pathname.substring(1); // Rimuove il prefisso '/'
      const ente = config[pathName] as any;
      if (ente) {
        setEnteConfig(ente);
        enteConfigReference.current = ente;
        setTitle(ente.enteName || '');

        const fetchImage = async (filename: any) => {
          const response = await utilsService.getImage(filename);
          return response;
        };

        const enteReference = `${ente.enteIPA}.png`

        fetchImage(`/loghiPA/${enteReference}`).then((logoUrl) => {
          if (logoUrl)
            setEnteLogo(logoUrl);
        }).then((logo) => {
          try {
            fetchImage(`/sfondiPA/${enteReference}`).then((backgroundUrl) => {
              if(backgroundUrl !== "")
                setBackgroundImage(backgroundUrl);
              else setBackgroundImage(background)
            });
          } catch (error) {
            setBackgroundImage(background)
          }
        })
      } else {
        disableComponents();
        showError(translate("noConfException"));
      }
    }
  }, [config]);

  useEffect(() => {
    if (enteConfig) {
      let active = true;
      enteConfigReference.current = enteConfig;
      const fetchData = async () => {
        try {
          
          const enteConfigRef = {...enteConfigReference.current} as any
          if(enteConfigRef != null) {
            const cimiteri = await cimService.fetchCimiteri(enteConfigRef['enteWSLocation']);
  
            if(active) {
              if (cimiteri != null || cimiteri !== undefined)
                setOptions(cimiteri)
              setLoading(false);
            }
          }
        } catch (error: any) {
          showError(error);
        }
      }

      fetchData();
    }
  }, [enteConfig]);

  useEffect(() => {

      //caricamento del config file al montaggio del tsx
      const fetchConfig = async () => {
        try {
          const data = await utilsService.getConfigFile()
          setConfig(data);
        } catch (error: any) {
          disableComponents();
          showError(error.message);
        }
      };

      fetchConfig();
        
      let active = true;

      const handleMessage = (event: MessageEvent) => {
        
          if (attivazioneListener) {
            attivazioneListener.cancel();
            attivazioneListener = undefined;
          }

          if (changeViewListener) {
            changeViewListener.cancel();
            changeViewListener = undefined;
          }

          if (filterListener) {
            filterListener.cancel();
            filterListener = undefined;
          }

          if (backwardListener) {
            backwardListener.cancel();
            backwardListener = undefined;
          }

          if (jsonListener) {
            jsonListener.cancel();
            jsonListener = undefined;
          }   
          
          if (desListener) {
            desListener.cancel();
            desListener = undefined;
          }

          if (showEntranceListener) {
            showEntranceListener.cancel();
            showEntranceListener = undefined;
          }

          // console.log('Dati ricevuti:', event);
          const childWindow = event.data.source; 
          attivazioneListener = postRobot.on("attivazioneGrafica", { window: childWindow }, async (_event: any) => {
            let updatedSearchParams = { ...searchParamsRef.current}
            let gisData;
            if(updatedSearchParams.idCimitero !== null && updatedSearchParams.idCimitero !== undefined) {
              const enteConfigRef = {...enteConfigReference.current} as any
              if(enteConfigRef != null) {
                gisData = await cimService.fetchGisData(updatedSearchParams.idCimitero, enteConfigRef['enteWSLocation']);
              }
            }
            return Promise.resolve([{ result : gisData }])
          });

          changeViewListener = postRobot.on("changeView", { window: childWindow  }, async (_event: any) => {
            
            let jsonGraficaCur;
            if (firstTimeParamsRef.current) {
              jsonGraficaCur = { ...jsonGraficaParamsRef.current}
              setJSONGrafica(jsonGraficaCur);
              setFirstTime(false)
              firstTimeParamsRef.current = false;
            } else {
              let idList = new CimSicraGisIdList();
              const id = _event.data.number;
              idList.idToRender = id;

              let supportRenderableIds: number[] = [];
              idList.renderableIds = supportRenderableIds

              const currentDefuntoElement = elementoDefuntoRef.current;
              currentDefuntoElement != null && idList.renderableIds.push(currentDefuntoElement);

              const secondaryId = _event.data.secondaryId
              secondaryId != null && idList.renderableIds.push(secondaryId);

              const enteConfigRef = {...enteConfigReference.current} as any
              if(enteConfigRef != null) {
                jsonGraficaCur = await cimService.extractChangeView(idList, enteConfigRef['enteWSLocation'])
              }
            }
              
            jsonGraficaParamsRef.current = jsonGraficaCur;
            setJSONGrafica(jsonGraficaCur);

            
            if (jsonGraficaParamsRef.current.tipo === "cimitero") 
              setViewPathDisabled(false)
            else
              setViewPathDisabled(true);

            if ('figli' in jsonGraficaCur) {
              const figli = jsonGraficaCur.figli as JSON[]
              checkForwardAvailability(figli)
            } else 
              setForwardDisabled(true)
            return Promise.resolve([{ result : jsonGraficaCur }])
          });

          filterListener = postRobot.on("getGisFiltersResults", { window: childWindow  }, async (_event: any) => {

            let filterResult;
            const enteConfigRef = {...enteConfigReference.current} as any
            if(enteConfigRef != null) {
              filterResult = await cimService.getGisFiltersResults(_event.data, enteConfigRef['enteWSLocation'])
            }
            return Promise.resolve([{ result : filterResult }])
          }); 

          backwardListener = postRobot.on("disableBackwards", { window: childWindow }, async (_event: any) => {
            setBackwardDisabled(true)
            return Promise.resolve([{ result : true }])
          });

          jsonListener = postRobot.on("updateJSON", { window: childWindow }, async (_event: any) => {
            jsonGraficaParamsRef.current = _event.data.loadedJSON
            setJSONGrafica(jsonGraficaParamsRef.current);

            if (jsonGraficaParamsRef.current.tipo === "cimitero") 
              setViewPathDisabled(false)
            else
              setViewPathDisabled(true);
            
            if ('figli' in jsonGraficaParamsRef.current) {
              const figli = jsonGraficaParamsRef.current.figli as JSON[]
              checkForwardAvailability(figli)
            } else 
              setForwardDisabled(true)

            return Promise.resolve([{ result : true }])
          });

          desListener = postRobot.on("askForDescription", { window: childWindow }, async (_event: any) => {
            
            let description = await handleOpenDes();

            return Promise.resolve([{ des : description}])
          })

          showEntranceListener = postRobot.on("showEntrance", { window: childWindow }, async (_event: any) => {
            const entranceName = _event.data.name
            if (entranceName !== undefined) {
              setMessageEntrance(entranceName)
              handleOpenEntrance();
            }
            return Promise.resolve([{ result : entranceName }])
          });
    }

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
      if(attivazioneListener !== undefined)
        attivazioneListener.cancel();
      attivazioneListener = undefined

      if(changeViewListener !== undefined)
        changeViewListener.cancel();
      changeViewListener = undefined;

      if(filterListener !== undefined)
        filterListener.cancel();
      filterListener = undefined;

      if(backwardListener !== undefined)
        backwardListener.cancel();
      backwardListener = undefined;

      if(jsonListener !== undefined)
        jsonListener.cancel();
      jsonListener = undefined;

      if(desListener !== undefined)
        desListener.cancel();
      desListener = undefined;

      if(showEntranceListener !== undefined)
        showEntranceListener.cancel();
      showEntranceListener = undefined;

      active = false;
    };

  }, []);

  const handleSelectChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    if (newValue !== undefined && newValue !== null) {
      if (newValue.descrizione !== undefined) 
        updateSubtitle(newValue.descrizione)
    } else 
      updateSubtitle("")
    setSelectedValue(newValue);
    selectedValueRef.current = newValue;
  };

  const handleDeceasedNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeceasedName(event.target.value);
  };

  const handleDeceasedSurnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeceasedSurname(event.target.value);
  };

  return (
    <div className="App">
      <CssBaseline />
      <ToastContainer 
        position="top-right" 
        autoClose={2000} 
        hideProgressBar={false} 
        newestOnTop={false} 
        closeOnClick 
        rtl={false} 
        pauseOnFocusLoss 
        draggable 
      />
      <header className="App-header">

      <Container
          component="main"
          maxWidth="lg"
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >

        {/* Sfondo */}
        <img src={backgroundImage} className="App-logo" alt="logo" />
        
        {/* Aggiungi icona utente in alto a destra */}
        <Box sx={{ position: 'absolute', top: 16, right: 50 }}>
          <IconButton
          edge="end"
          aria-label="account of current user"
          aria-controls="user-menu"
          aria-haspopup="true"
          onClick={handleUserIconClick}
          color="inherit"
            >
            <AccountCircle />
          </IconButton>
        </Box>

        {/* Menu slide da destra */}
        <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={handleDrawerClose}
            sx={{
                '& .MuiDrawer-paper': {
                    backgroundColor: '#2c3e50',
                    color: 'white',
                    width: 300,
                    transition: 'width 0.3s',
                },
            }}
        >
            <Box
                sx={{ width: 300 }}
                role="presentation"
            >
                {/* Intestazione del Drawer */}
                <Box sx={{ padding: 2, display: 'flex', alignItems: 'center' }}>
                        {isLoggedIn ? (
                            <>
                                <Avatar sx={{ bgcolor: '#34495e', marginRight: 2 }}>{userName.substring(0,1)}</Avatar>
                                <Typography variant="h6">{userName}</Typography>
                            </>
                        ) : (
                            <Typography variant="h6">{t('reservedArea')}</Typography>
                        )}
                </Box>
                <Divider sx={{ backgroundColor: 'grey' }} />
                <List>
                        {isLoggedIn ? (
                            <>
                                {/* <ListItemButton>
                                    <ListItemIcon sx={{ color: 'white' }}>
                                        <Person />
                                    </ListItemIcon>
                                    <ListItemText primary="Profile" />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon sx={{ color: 'white' }}>
                                        <Settings />
                                    </ListItemIcon>
                                    <ListItemText primary="Settings" />
                                </ListItemButton> */}
                                <ListItemButton onClick={handleLogout}>
                                    <ListItemIcon sx={{ color: 'white' }}>
                                        <ExitToApp />
                                    </ListItemIcon>
                                    <ListItemText primary="Logout" />
                                </ListItemButton>
                            </>
                        ) : (
                          <LoginForm onLogin={handleLogin} />
                        )}
                </List>
            </Box>
        </Drawer>

        <div>
          <Dialog open={isGeolocationDialogOpen}>
          <DialogTitle>{t('geolocationRequestTitle')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('geolocationRequestText')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button
              variant="contained"
              style={{
                backgroundColor: '#b0c4de', 
                color: 'black',
                borderRadius: '20px',
                transition: 'background-color 0.3s, color 0.3s',
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = '#4682b4'; // Blu meno acceso
                e.currentTarget.style.color = 'white';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = '#b0c4de'; // Colore bianco/grigio chiaro
                 e.currentTarget.style.color = 'black';
              }}
              onClick={handleGeolocationDisagree}
                >
              {t('denyGeolocation')}
          </Button>
          <Button
              variant="contained"
              style={{
                 backgroundColor: '#b0c4de', 
                 color: 'black',
                 borderRadius: '20px',
                 transition: 'background-color 0.3s, color 0.3s',
               }}
              onMouseOver={(e) => {
                 e.currentTarget.style.backgroundColor = '#4682b4'; // Blu meno acceso
                 e.currentTarget.style.color = 'white';
              }}
              onMouseOut={(e) => {
                 e.currentTarget.style.backgroundColor = '#b0c4de'; // Colore bianco/grigio chiaro
                 e.currentTarget.style.color = 'black';
              }}
              onClick={handleGeolocationAgree}
              >
              {t('allowGeolocation')}
          </Button>
          </DialogActions>
          </Dialog>
        </div>
        {/* Riquadro trasparente nero */}
        <div className="overlay">
          <div className="content">
            {/* Contenuto al centro */}
            <Box className="content" p={4}>
            <Box display="flex" justifyContent="center" mb={2} // Margine sotto l'immagine 
            >
              {enteLogo && (
                  <Avatar 
                    src={enteLogo} 
                    alt="Logo dell'ente" 
                    sx={{ width: 100, height: 100, marginBottom: 2 }}
                  />
                )}
              </Box>
              {/* Titolo e sottotitolo */}
              <Typography variant="h3">{title}</Typography>
              <Typography variant="subtitle1">{subtitle}</Typography>
              
              {/* Spazio */}
              <Box mb={2}></Box>
          
              {/* Combobox con transizione */}
              <Fade in={true} timeout={1000}>
                <div>
                  <Box mt={2} style={{ margin: 'auto', width: 300 }}>
                    <Autocomplete
                        id="cimiteri-combo-box"
                        options={options}
                        value={selectedValue}
                        onChange={handleSelectChange}
                        loading={loading}
                        open={open}
                        disabled = {isComboDisabled}
                        onOpen={() => {
                          setOpen(true);
                        }}
                        onClose={() => {
                          setOpen(false);
                        }}
                        isOptionEqualToValue={(option, value) => option.descrizione === value.descrizione}
                        getOptionLabel={(option) => option.descrizione}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('selectCimitero')}
                            InputLabelProps={{ style: { color: 'white' }}}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                              style: { color: 'white !important' },
                              sx: {
                                '& .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'lightgrey',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'darkgrey',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderColor: '#1976d2',
                                },
                                '& input': {
                                    color: 'white !important', // Imposta il colore del testo qui
                                    'WebkitTextFillColor': 'white !important', // Colore del testo per autocompletamento
                                    '&:-webkit-autofill': {
                                      'WebkitBoxShadow': '0 0 0 1000px transparent inset !important',
                                      'boxShadow': '0 0 0 1000px transparent inset !important',
                                      'backgroundColor': 'transparent !important',
                                    }
                                  },
                              }
                            }}
                            sx={{ backgroundColor: 'transparent' }}
                          />
                        )}
                      />
                  </Box>

                  {/* Spazio */}
                  <Box mb={2}></Box>

                  {/* TextField nome */}
                  <Box mt={2} style={{ margin: 'auto', width: 300 }}>
                    <TextField
                      id="deceased-name"
                      label={t('defuntoName')}
                      value={deceasedName}
                      disabled = {isNomeDisabled}
                      onChange={handleDeceasedNameChange}
                      InputLabelProps={{ style: { color: 'white' }}}
                      InputProps={{
                        style: { color: 'white' },
                        sx: {
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'aliceblue',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'darkgrey',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1976d2',
                          },
                          '& input:-webkit-autofill': {
                            'WebkitBoxShadow': '0 0 0 1000px transparent inset !important',
                            'boxShadow': '0 0 0 1000px transparent inset !important',
                            'backgroundColor': 'transparent !important',
                            'WebkitTextFillColor': 'white !important', 
                          }
                        }
                      }}
                      sx={{ backgroundColor: 'transparent' }}
                    />
                  </Box>

                  {/* Spazio */}
                  <Box mb={2}></Box>

                  {/* TextField cognome */}
                  <Box mt={2} style={{ margin: 'auto', width: 300 }}>
                    <TextField
                      id="deceased-surname"
                      label={t('defuntoSurname')}
                      value={deceasedSurname}
                      disabled = {isCognomeDisabled}
                      onChange={handleDeceasedSurnameChange}
                      InputLabelProps={{ style: { color: 'white' }}}
                      InputProps={{
                        style: { color: 'white' },
                        sx: {
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'aliceblue',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'darkgrey',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1976d2',
                          },
                          '& input:-webkit-autofill': {
                            'WebkitBoxShadow': '0 0 0 1000px transparent inset !important',
                            'boxShadow': '0 0 0 1000px transparent inset !important',
                            'backgroundColor': 'transparent !important',
                            'WebkitTextFillColor': 'white !important', 
                          }
                        }
                      }}
                      sx={{ backgroundColor: 'transparent' }}
                    />
                  </Box>

                  {/* Pulsante "Ricerca" */}
                  <Box mt={2} style={{ textAlign: 'center' }}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: isSearchDisabled ? 'lightgrey' : '#b0c4de', 
                        color: isSearchDisabled ? 'darkgrey' : 'black',
                        borderRadius: '20px',
                        transition: 'background-color 0.3s, color 0.3s',
                      }}
                      onMouseOver={(e) => {
                        e.currentTarget.style.backgroundColor = '#4682b4'; // Blu meno acceso
                        e.currentTarget.style.color = 'white';
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.backgroundColor = '#b0c4de'; // Colore bianco/grigio chiaro
                        e.currentTarget.style.color = 'black';
                      }}
                      onClick={handleResearchClick}
                      disabled={isSearchDisabled}
                    >
                      {t('research')}
                    </Button>
                </Box>
                </div>
              </Fade>
            </Box>
          </div>
        </div>
        </Container>
      </header>
      {/* Inserisci il Footer qui */}
      <Footer />  

      <Fade in={showPopup} timeout={500}>
        <div className="popup">
          {showPopup && (
            <div className="popup-content" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
              <IconButton
                style={{ position: 'absolute', top: '10px', right: '10px' }}
                onClick={handleMenuOpen}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem disabled = {!isLoggedIn} onClick={isPathFinderMode? handleNavMode : handlePathfinderClick }>{ isPathFinderMode? translate('backNav') : translate('pathManage') }</MenuItem>
                {isPathFinderMode && (
                  <MenuItem onClick={savePathFinderRoutes}> {t('saveRoutes')} </MenuItem>
                )}
                {isPathFinderMode && (
                  <MenuItem onClick={isPointMode? saveEntries : insertEntries }>{ isPointMode? translate('saveEntries') : translate('insertEntries') }</MenuItem>
                )}
              </Menu>
              <iframe
                id="graficaIFrame"
                src="/static/CemeteryGates/indexCemeteryGates.html"
                title="Grafica"
                style={{ width: '1600px', height: '800px', border: 'none' }}
              ></iframe>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '20px',
                  width: '100%',
                }}
              >
              <FormControlLabel
                control={<Switch checked={switchChecked} onChange={handleSwitchChange} />}
                label= {t("highlightPaths")}
                disabled = {switchDisabled}
                style={{ marginLeft: '40px' }}
                />
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', flex: 1 }}>
                  <Button
                    variant="contained"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: isBackwardDisabled || isPathFinderMode ? 'lightgrey' : '#b0c4de',
                      color: isBackwardDisabled || isPathFinderMode? 'darkgrey' : 'black',
                      borderRadius: '20px',
                      transition: 'background-color 0.3s, color 0.3s',
                      padding: '10px 20px',
                      marginLeft: '-240px'
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = '#4682b4';
                      e.currentTarget.style.color = 'white';
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = '#b0c4de';
                      e.currentTarget.style.color = 'black';
                    }}
                    onClick={handleBackClick}
                    disabled={isBackwardDisabled || isPathFinderMode}
                  >
                    <img
                      src="/static/CemeteryGates/arrow.png"
                      alt="Indietro"
                      style={{
                        marginRight: '8px',
                        width: '10px',
                        height: '10px',
                        transform: 'rotate(90deg)',
                        filter: isBackwardDisabled || isPathFinderMode
                          ? 'brightness(50%) saturate(0%) hue-rotate(0deg) contrast(90%)'
                          : 'none',
                      }}
                    />
                    {t('backward')}
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: isPathFinderMode || viewPathDisabled ? 'lightgrey' : '#b0c4de',
                      color: isPathFinderMode || viewPathDisabled ? 'darkgrey' : 'black',
                      borderRadius: '20px',
                      transition: 'background-color 0.3s, color 0.3s',
                      padding: '10px 30px',
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = '#4682b4';
                      e.currentTarget.style.color = 'white';
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = '#b0c4de';
                      e.currentTarget.style.color = 'black';
                    }}
                    onClick={viewPathAction}
                    disabled={isPathFinderMode || viewPathDisabled}
                  >
                    {viewPathButton}
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: isForwardDisabled || isPathFinderMode ? 'lightgrey' : '#b0c4de',
                      color: isForwardDisabled || isPathFinderMode ? 'darkgrey' : 'black',
                      borderRadius: '20px',
                      transition: 'background-color 0.3s, color 0.3s',
                      padding: '10px 30px',
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = '#4682b4';
                      e.currentTarget.style.color = 'white';
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = '#b0c4de';
                      e.currentTarget.style.color = 'black';
                    }}
                    onClick={handleForwardClick}
                    disabled={isForwardDisabled || isPathFinderMode}
                  >
                    {t('forward')}
                    <img
                      src="/static/CemeteryGates/arrow.png"
                      alt="Avanti"
                      style={{
                        marginLeft: '8px',
                        width: '10px',
                        height: '10px',
                        transform: 'rotate(270deg)',
                        filter: isForwardDisabled || isPathFinderMode
                          ? 'brightness(50%) saturate(0%) hue-rotate(0deg) contrast(90%)'
                          : 'none',
                      }}
                    />
                  </Button>
                </div>
              </div>
              <div style={{ marginTop: '10px' }}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#b0c4de',
                    color: 'black',
                    borderRadius: '20px',
                    transition: 'background-color 0.3s, color 0.3s',
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = '#4682b4';
                    e.currentTarget.style.color = 'white';
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = '#b0c4de';
                    e.currentTarget.style.color = 'black';
                  }}
                  onClick={handleClosePopup}
                >
                  {t('close')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Fade>

      {/* Popup dei defunti */}
      <Fade in={showDefuntiTable} timeout={500}>
        <div className="popup">
          {showDefuntiTable && (
            <div className="popup-content">
              {/* Componente DefuntiTab */}
              <DefuntiTab isOpen={showDefuntiTable} onClose={handleCloseTab} defuntiArray={defuntiArray} onDefuntoClick={handleDefuntoClick}/>
              
              {/* Pulsante per chiudere il popup */}
              <Button variant="contained"
                style={{
                  backgroundColor: '#b0c4de', // Colore bianco/grigio chiaro
                  color: 'black',
                  borderRadius: '20px',
                  transition: 'background-color 0.3s, color 0.3s',
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = '#4682b4'; // Blu meno acceso
                  e.currentTarget.style.color = 'white';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = '#b0c4de'; // Colore bianco/grigio chiaro
                  e.currentTarget.style.color = 'black';
                }}
                onClick={handleCloseTab}
              >
                {t('close')}
              </Button>
            </div>
          )}
        </div>
      </Fade>

      <div>
      {/* Componente che mostra il messaggio di avviso */}
        {errorMessage && (
          <AlertDialog
            open={true}
            onClose={closeError}
            message={errorMessage}
          />
        )}
      </div>
      <div>
      <Dialog open={isPopupVisible} onClose={handleCloseDes}>
                <DialogTitle>{t('newPoint')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    {t('insertDes')}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label={t('desHeader')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={description}
                        onChange={handleDescriptionChange}
                        inputProps={{ maxLength: 20 }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="primary">
                    {t('confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
      </div>
      <div>
      <Dialog open={isPointTableVisible} onClose={handleClosePointsTab}>
                <DialogTitle>{t('accessTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('accessDialog')}
                    </DialogContentText>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                            {tableData.map((row, index) => {
                                    const trimmedIdentity = row.identity.substring(STARTING_POINT.length);
                                    let isNearest = row.nearest === true
                                    return (
                                      <TableRow
                                        key={index}
                                        onClick={() => handlePointClick(row.identity)}
                                        style={{ cursor: 'pointer', transition: 'background-color 0.3s' }}
                                        sx={{ '&:hover': { backgroundColor: '#f0f0f0' } }}
                                      >
                                        <TableCell>{trimmedIdentity}</TableCell>
                                        {isNearest && (
                                          <TableCell>
                                            <Tooltip title={t('locationTooltip')}>
                                              <div className="pulse-container">
                                                  <IconButton size="small" aria-label="nearest-point-icon">
                                                    <LocationOnIcon className="pulse-icon" style={{ color: 'green' }} />
                                                  </IconButton>
                                              </div>
                                            </Tooltip>
                                          </TableCell>
                                        )}
                                      </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant="caption" style={{ display: 'block', marginTop: '8px', fontStyle: 'italic' }}>
                      {t('nearestAccessNote')}
                    </Typography>
                </DialogContent>
                <DialogActions>
                <Button variant="contained"
                  style={{
                    backgroundColor: '#b0c4de', // Colore bianco/grigio chiaro
                    color: 'black',
                    borderRadius: '20px',
                    transition: 'background-color 0.3s, color 0.3s',
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = '#4682b4'; // Blu meno acceso
                    e.currentTarget.style.color = 'white';
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = '#b0c4de'; // Colore bianco/grigio chiaro
                    e.currentTarget.style.color = 'black';
                  }}
                  onClick={handleClosePointsTab}
                >
                  {t('abort')}
                </Button>
                </DialogActions>
            </Dialog>
      </div>
      <Fade in={openEntrance} timeout={500}>
        <div>
              {/* Popup */}
              <Popup open={openEntrance} onClose={handleCloseEntrance} message={messageEntrance} />
        </div>
      </Fade>
      <Fade in={showMap} timeout={500}>
        <div>
          {showMap && (
            <React.Suspense>
              <MapComponent coordinates={coordinates} visible={showMap} onCloseButton={handleCloseGMaps} />
            </React.Suspense>
          )}
        </div>
      </Fade>
      {/* Popup di caricamento */}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loadingPopup}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default App;
