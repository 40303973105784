import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { useTranslation } from "react-i18next";

interface PopupProps {
    open: boolean;
    onClose: () => void; 
    message: string; 
}

const Popup: React.FC<PopupProps> = ({ open, onClose, message }) => {

    const { t, i18n } = useTranslation();

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t('entranceDetail')}</DialogTitle>
            <DialogContent style={{ textAlign: 'center' }}>
                <p>{message}</p>
            </DialogContent>
            <DialogActions>
                <Button 
                onClick={onClose} 
                variant="contained"
                style={{
                backgroundColor: '#b0c4de',
                color: 'black',
                borderRadius: '20px',
                transition: 'background-color 0.3s, color 0.3s',
                cursor: 'pointer',
                }}
                onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = '#4682b4';
                e.currentTarget.style.color = 'white';
                }}
                onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = '#b0c4de';
                e.currentTarget.style.color = 'black';
                }}>
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Popup;